import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
//import './referral.css';
import Img from 'gatsby-image';
import Button from '../components/buttons/button';
import { ButtonColor, ButtonStyle } from '../models/button-style';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import axios from 'axios';
import { any } from 'prop-types';
import { Route } from '../models/site-links';

const ReferralPage = () => {
  const data = useStaticQuery(graphql`
    query {
      radioselected: file(relativePath: { eq: "cancel/radio-button-selected.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      radio: file(relativePath: { eq: "cancel/radio-button.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      radioSelected: file(relativePath: { eq: "cancel/radio-button-selected.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      logo: file(relativePath: { eq: "cancel/PS Logo_Horizontal_Gradient_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      call: file(relativePath: { eq: "cancel/call.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      mail: file(relativePath: { eq: "cancel/mail.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const [isLoading, setIsLoading] = useState(true);

  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [referralName, setReferralName] = useState('');
  const [venueName, setVenueName] = useState('');
  const [referralEmail, setReferralEmail] = useState('');
  const [referralPhoneNum, setReferralPhoneNum] = useState('');
  const [referralName2, setReferralName2] = useState('');
  const [venueName2, setVenueName2] = useState('');
  const [referralEmail2, setReferralEmail2] = useState('');
  const [referralPhoneNum2, setReferralPhoneNum2] = useState('');

  const [showSecondReferral, setShowSecondReferral] = useState(false);
  const [showAddAnotherReferral, setShowAddAnotherReferal] = useState(true);
  const [showRemoveAdditionalReferral, setShowRemoveAdditionalReferral] = useState(false);

  const formData = {
    customerName: clientName,
    customerEmail: clientEmail,
    referralName: referralName,
    referralVenueName: venueName,
    referralEmail: referralEmail,
    referralPhoneNumber: referralPhoneNum,
  };

  const formData2 = {
    customerName: clientName,
    customerEmail: clientEmail,
    referralName: referralName2,
    referralVenueName: venueName2,
    referralEmail: referralEmail2,
    referralPhoneNumber: referralPhoneNum2,
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (showAddAnotherReferral) {
      axios
        .post('https://core.prod.patronscan.servallapps.com/api/v1/referral/finalize/', formData)
        .then(() => {
          navigate(Route.ReferralSuccess);
        });
    }

    if (!showAddAnotherReferral) {
      axios.post(
        'https://core.prod.patronscan.servallapps.com/api/v1/referral/finalize/',
        formData,
      );
      axios
        .post('https://core.prod.patronscan.servallapps.com/api/v1/referral/finalize/', formData2)
        .then(() => {
          navigate(Route.ReferralSuccess);
        });
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      setIsLoading(false);
    }, 2000);
  });

  if (isLoading) {
    return (
      <div>
        <div className="flex justify-center">
          <div className="container">
            <div style={{ marginTop: '530px', marginBottom: '670px' }}>
              <div className="mb-20 mt-10">
                <FontAwesomeIcon
                  className="spinner"
                  icon={faSpinner}
                  color="#507EE0"
                  pulse
                  size="6x"
                />
                <p className="loading-text">Please wait a moment</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (!isLoading) {
    return (
      <div>
        <div className="flex justify-center">
          <div className="mb-20 mt-32 h-full">
            <p className="text-cancelheader text-center font-semibold w-full">Refer a Friend!</p>
            <p className="text-lb leading-loose decoration-rose-500 font-medium text-center justify-center self-center lap:block tab:hidden xr:hidden">
              Patronscan is a leading provider of ID scanning and age verification technology for
              the hospitality and events industry.
            </p>
            <p className="text-lb leading-loose decoration-rose-500 font-medium text-center justify-center self-center lap:block tab:hidden xr:hidden">
              Referring your friends is a great way to help them keep their venues and events safe
              and secure. To refer your friends,
            </p>
            <p className="text-lb leading-loose decoration-rose-500 font-medium text-center justify-center self-center lap:block tab:hidden xr:hidden">
              simply fill out the information below.
            </p>
            <p className=" text-lb leading-loose decoration-rose-500 font-medium text-center justify-center self-center lap:hidden xr:px-56 tab:px-20">
              Patronscan is a leading provider of ID scanning and age verification technology for
              the hospitality and events industry. Referring your friends is a great way to help
              them keep their venues and events safe and secure. To refer your friends, simply fill
              out the information below.
            </p>
            <form onSubmit={submitForm}>
              <div className="flex lap:flex-row lap:justify-around lap:mt-12 text-xl font-normal text-center tab:flex-col xr:flex-col  ">
                <div className="call-container2">
                  <p className="lap:-ml-40 tab:-ml-44 lap:mb-2 lap:-mt-2 text-referral font-semibold xr:mt-16 tab:mb-4 xr:-ml-44 xr:mb-4">
                    Your Information
                  </p>
                  <p className="text-neutral-700 text-mb font-medium -ml-64 ">Full name*</p>

                  <input
                    className="w-326px h-12 rounded-lg p-4 text-gray-800 border border-neutral text-mb xr:mb-20"
                    placeholder=" Full name"
                    required
                    type="text"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                  />
                </div>
                <div className="mail-container2 xr:mb-4">
                  <p className="text-neutral-700 text-mb font-medium ml-220px lap:mt-md xr:-mt-220px xr:-ml-56">
                    Email address*
                  </p>
                  <input
                    className="w-326px h-12 rounded-lg p-4 text-gray-800 border border-neutral text-mb"
                    placeholder=" email@email.com"
                    required
                    type="text"
                    value={clientEmail}
                    onChange={(e) => setClientEmail(e.target.value)}
                  />
                </div>
              </div>
              <p className="lap:-mt-235px lap:ml-155px text-referral font-semibold  xr:ml-220px xr:-mt-32 tab:ml-270px  xr:mr-440px xr:min-w-1/2">
                Referral Information
              </p>
              <div className="flex lap:flex-row lap:justify-around lap:-mt-4 text-xl font-normal text-center tab:flex-col xr:flex-col h-fit">
                <div className="call-container2">
                  <p className="text-neutral-700 text-mb font-medium -ml-64 xr:mt-4 lap:mt-8">
                    Full name*
                  </p>
                  <input
                    className="w-326px h-12 rounded-lg p-4 text-gray-800 border border-neutral text-mb"
                    placeholder=" Full name"
                    required
                    type="text"
                    value={referralName}
                    onChange={(e) => setReferralName(e.target.value)}
                  />
                </div>
                <div className="mail-container2 lap:mt-40 tab:-mt-6">
                  <p className="text-neutral-700 text-mb font-medium -ml-60 xr:-mt-32">
                    Venue name*
                  </p>
                  <input
                    className="w-326px h-12 rounded-lg p-4 text-gray-800 border border-neutral text-mb"
                    placeholder=" Venue name"
                    required
                    type="text"
                    value={venueName}
                    onChange={(e) => setVenueName(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex lap:flex-row lap:justify-around lap:-mt-44 text-xl font-normal text-center tab:flex-col xr:flex-col h-fit">
                <div className="call-container2">
                  <p className="text-neutral-700 text-mb font-medium -ml-56 xr:-mt-8 lap:mt-0">
                    Email address*
                  </p>
                  <input
                    className="w-326px h-12 rounded-lg p-4 text-gray-800 border border-neutral text-mb"
                    placeholder=" email@email.com"
                    required
                    type="text"
                    value={referralEmail}
                    onChange={(e) => setReferralEmail(e.target.value)}
                  />
                </div>
                <div className="mail-container2 tab:-mt-4 lap:mt-0">
                  <p className="text-neutral-700 text-mb font-medium -ml-56 xr:-mt-32 lap:mt-0">
                    Phone number*
                  </p>
                  <input
                    className="w-326px h-12 rounded-lg p-4 text-gray-800 border border-neutral text-mb"
                    placeholder=" 403-123-4567"
                    required
                    value={referralPhoneNum}
                    onChange={(e) => setReferralPhoneNum(e.target.value)}
                  />
                </div>
              </div>
              {showAddAnotherReferral ? (
                <p
                  className="lap:-mt-40 lap:ml-48 text-primary-700 hover:text-primary-600 text-mb font-semibold cursor-pointer xr:-mt-4 xr:ml-230px tab:ml-275px xr:mb-8"
                  onClick={(e) => {
                    setShowSecondReferral(true);
                    setShowAddAnotherReferal(false);
                    setShowRemoveAdditionalReferral(true);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add another referral
                </p>
              ) : null}

              {showRemoveAdditionalReferral ? (
                <p
                  className="lap:-mt-40 lap:ml-48 text-primary-700 hover:text-primary-600 text-mb font-semibold cursor-pointer xr:-mt-4 xr:ml-230px tab:ml-275px lap:-mb-12"
                  onClick={(e) => {
                    setShowSecondReferral(false);
                    setShowRemoveAdditionalReferral(false);
                    setShowAddAnotherReferal(true);
                    setReferralName2('');
                    setVenueName2('');
                    setReferralEmail2('');
                    setReferralPhoneNum2('');
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} /> Remove additional referral
                </p>
              ) : null}

              {showSecondReferral ? (
                <>
                  <div className="h-fit">
                    <div className="flex lap:flex-row lap:justify-around lap:mt-10 text-xl font-normal text-center tab:flex-col xr:flex-col ">
                      <div className="call-container2">
                        <p className="text-neutral-700 text-mb font-medium -ml-64 xr:mt-8">
                          Full name*
                        </p>
                        <input
                          className="w-326px h-12 rounded-lg p-4 text-gray-800 border border-neutral text-mb"
                          placeholder=" Full name"
                          required
                          type="text"
                          value={referralName2}
                          onChange={(e) => setReferralName2(e.target.value)}
                        />
                      </div>
                      <div className="mail-container2 tab:-mt-4 lap:mt-40">
                        <p className="text-neutral-700 text-mb font-medium -ml-60 mt- xr:-mt-32">
                          Venue Name*
                        </p>
                        <input
                          className="w-326px h-12 rounded-lg p-4 text-gray-800 border border-neutral text-mb"
                          placeholder=" Venue name"
                          required
                          type="text"
                          value={venueName2}
                          onChange={(e) => setVenueName2(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex lap:flex-row lap:justify-around lap:-mt-44 text-xl font-normal text-center tab:flex-col xr:flex-col -mb-40">
                      <div className="call-container2">
                        <p className="text-neutral-700 text-mb font-medium -ml-56 xr:-mt-8 lap:mt-0">
                          Email address*
                        </p>
                        <input
                          className="w-326px h-12 rounded-lg p-4 text-gray-800 border border-neutral text-mb"
                          placeholder=" email@email.com"
                          required
                          type="text"
                          value={referralEmail2}
                          onChange={(e) => setReferralEmail2(e.target.value)}
                        />
                      </div>
                      <div className="mail-container2 mb-32 tab:-mt-4">
                        <p className="text-neutral-700 text-mb font-medium -ml-56 lap:mt-4 xr:-mt-32">
                          Phone number*
                        </p>
                        <input
                          className="w-326px h-12 rounded-lg p-4 text-gray-800 border border-neutral text-mb"
                          placeholder=" 403-123-4567"
                          required
                          type="text"
                          value={referralPhoneNum2}
                          onChange={(e) => setReferralPhoneNum2(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <button
                type="submit"
                className="flex block hover:text-grey-dark transition-color lg:text-base lap:ml-630px lap:mt-8 tab:m-auto xr:m-auto xr:mt-2"
              >
                <div className=" text-shades-0 bg-primary hover:bg-primary-400 border-2 border-primary flex truncate justify text-md font-medium py-2.5 px-10 tab:px-20 my-4 cursor-pointer rounded-lg">
                  Submit
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
};
export default ReferralPage;
